.bankButton {
  height: 56px;
  margin-bottom: 6px;
  background-color: #fff;
  border-radius: 14px;

  &:hover {
    background-color: rgba($color: #2293fb, $alpha: 0.3);
  }
}
.accountButton {
  display: flex;
  height: 72px;
  padding: 12px;
  margin-bottom: 8px;
  background-color: #fff;
  border-radius: 14px;

  &:hover {
    background-color: rgba($color: #2293fb, $alpha: 0.3);
  }
}

.accountName {
  font-size: 16px;
  color: #172230;
  text-align: center;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: clip;
}

.accountId {
  font-size: 12px;
  color: #172230;
}

.accountBalance {
  font-size: 16px;
  color: #172230;
  text-align: center;
  white-space: nowrap;
  text-overflow: clip;
}

.recipientLeft {
  width: 30%;
  font-size: 16px;
  color: #172230;
  text-align: left;
}

.recipientRight {
  width: 70%;
  font-size: 16px;
  color: #172230;
  text-align: right;
}


.balanceNumber {
  display: inline-block;
  width: calc(100% - 30px);
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow:initial;
  margin-right: 10px;
}

.itemValue {
  border: 1px solid #172230;
  padding: 5px 20px;
  width: auto;
  border-radius: 20px;
}

.break {
  flex-basis: 100%;
  height: 0;
}
.item {
  flex-grow: 1
}

.warning {
  border: 1px solid #FFF5E1;
  padding: 10px;
  border-radius: 14px;
  background: #FFF5E1;
  color: #fa8700;

}

.sfcircle {
  align-self: center;
  width: 64px;
  height: 64px;
  line-height: 64px;
  border-radius: 50%;
  font-size: 24px;
  color: #FF3C3C;
  text-align: center;
  background: #F1F2F7
}

.sfheader {
  color: #172230;
  font-weight: bold;
  font-size: 24px;
  margin-top: 24px;
}

.sftext {
  color: #172230;
  font-size: 18px;
}