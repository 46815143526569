.boxContainer {
  padding: 0;
  margin-top: 0;
  position: relative;
}

.backButton {
  position: absolute;
  left: -10px;
  top: -7px;
}

.backIcon {
  padding: 0;
}

.p0 {
  padding: 0;
}

.title {
  font-size: 24px;
  color: #172230;
  padding-left: 30px;
  padding-right: 30px;
  text-align: center;
  font-weight: 700;
  white-space: break-spaces;
}

.desc {
  font-size: 18px;
  color: #172230;
  text-align: center;
}

.nextStep {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background-color: #fff;
  border: 1px solid #3e5a7d;
}

.currentStep {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background-color: #2293fb;
}

.line {
  width: 14px;
  height: 1px;
  background-color: #3e5a7d;
}
