.info {
    @apply 
    text-center
      text-gray-800
      text-xs
      font-normal
      mb-3
      
  }
  
.h1 {
  font-size: 28px;
  line-height: 33.60px;
  @apply
  text-gray-800
  font-bold
}

.secondline {
  @apply
  text-gray-800
  text-lg
  font-normal
}

.visible {
  opacity: 1;
  transition: opacity 0.5s ease-out;}

.hidden {
  opacity: 0;
}

.marchants_list {
  display: flex;
  flex-direction: column;
}
