.input
{
    font-size: 19px;
    border-top-left-radius: 14px;
    border-bottom-left-radius: 14px;
    padding-left: 1rem;

    @apply
    w-full
    h-full
}

.suffix {
    border-top-right-radius: 14px;
    border-bottom-right-radius: 14px;
}
.suffiximg {
    @apply
    ml-2
    mr-2
    h-6
}
.flabel {
    position: fixed;
    top:0;
    color: #A9A9A9;

    label {
        padding-left: 1rem;

    }

}
